define("ember-power-calendar-utils/index", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.add = add;
  _exports.diff = diff;
  _exports.endOf = endOf;
  _exports.endOfWeek = endOfWeek;
  _exports.formatDate = formatDate;
  _exports.getDefaultLocale = getDefaultLocale;
  _exports.getWeekdays = getWeekdays;
  _exports.getWeekdaysMin = getWeekdaysMin;
  _exports.getWeekdaysShort = getWeekdaysShort;
  _exports.isAfter = isAfter;
  _exports.isBefore = isBefore;
  _exports.isBetween = isBetween;
  _exports.isSame = isSame;
  _exports.isoWeekday = isoWeekday;
  _exports.localeStartOfWeek = localeStartOfWeek;
  _exports.normalizeCalendarDay = normalizeCalendarDay;
  _exports.normalizeCalendarValue = normalizeCalendarValue;
  _exports.normalizeDate = normalizeDate;
  _exports.normalizeDuration = normalizeDuration;
  _exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  _exports.normalizeRangeActionValue = normalizeRangeActionValue;
  _exports.startOf = startOf;
  _exports.startOfWeek = startOfWeek;
  _exports.weekday = weekday;
  _exports.withLocale = withLocale;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function add(date, quantity, unit) {
    return (0, _moment.default)(date).add(quantity, unit).toDate();
  }
  function formatDate(date, format) {
    var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
    if (locale) {
      return withLocale(locale, function () {
        return (0, _moment.default)(date).format(format);
      });
    } else {
      return (0, _moment.default)(date).format(format);
    }
  }
  function startOf(date, unit) {
    return (0, _moment.default)(date).startOf(unit).toDate();
  }
  function endOf(date, unit) {
    return (0, _moment.default)(date).endOf(unit).toDate();
  }
  function weekday(date) {
    return (0, _moment.default)(date).weekday();
  }
  function isoWeekday(date) {
    return (0, _moment.default)(date).isoWeekday();
  }
  function getWeekdaysShort() {
    return _moment.default.weekdaysShort();
  }
  function getWeekdaysMin() {
    return _moment.default.weekdaysMin();
  }
  function getWeekdays() {
    return _moment.default.weekdays();
  }
  function isAfter(date1, date2) {
    return (0, _moment.default)(date1).isAfter(date2);
  }
  function isBefore(date1, date2) {
    return (0, _moment.default)(date1).isBefore(date2);
  }
  function isSame(date1, date2, unit) {
    return (0, _moment.default)(date1).isSame(date2, unit);
  }
  function isBetween(date, start, end, unit, inclusivity) {
    return (0, _moment.default)(date).isBetween(start, end, unit, inclusivity);
  }
  function diff(date1, date2) {
    return (0, _moment.default)(date1).diff(date2);
  }
  function normalizeDate(dateOrMoment) {
    if (dateOrMoment === undefined || dateOrMoment === null || dateOrMoment === '' || dateOrMoment instanceof Date) {
      return dateOrMoment;
    } else {
      return dateOrMoment.toDate();
    }
  }
  function normalizeRangeActionValue(val) {
    return {
      date: val.date,
      moment: {
        start: val.date.start ? (0, _moment.default)(val.date.start) : val.date.start,
        end: val.date.end ? (0, _moment.default)(val.date.end) : val.date.end
      }
    };
  }
  function normalizeMultipleActionValue(val) {
    return {
      date: val.date,
      moment: val.date ? val.date.map(function (e) {
        return (0, _moment.default)(e);
      }) : val.date
    };
  }
  function normalizeCalendarDay(day) {
    day.moment = (0, _moment.default)(day.date);
    return day;
  }
  function withLocale(locale, fn) {
    var returnValue;
    if (locale) {
      var previousLocale = _moment.default.locale();
      _moment.default.locale(locale);
      returnValue = fn();
      _moment.default.locale(previousLocale);
    } else {
      returnValue = fn();
    }
    return returnValue;
  }
  function normalizeCalendarValue(value) {
    if (value) {
      return {
        date: value.date,
        moment: value.date ? (0, _moment.default)(value.date) : undefined
      };
    }
    return {
      date: undefined,
      moment: undefined
    };
  }
  function normalizeDuration(value) {
    if (value === null) {
      return null;
    }
    if (_moment.default.isDuration(value)) {
      return value.asMilliseconds();
    }
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      var _value$match = value.match(/(\d+)(.*)/),
        _value$match2 = _slicedToArray(_value$match, 3),
        quantity = _value$match2[1],
        units = _value$match2[2];
      units = units.trim() || "days";
      return _moment.default.duration(parseInt(quantity, 10), units).asMilliseconds();
    }
  }
  function getDefaultLocale() {
    return _moment.default.locale();
  }
  function localeStartOfWeek(locale) {
    var now = new Date();
    var day = withLocale(locale, function () {
      return formatDate(startOf(now, 'week'), 'dddd');
    });
    var idx = withLocale(locale, getWeekdays).indexOf(day);
    return idx >= 0 ? idx : 0;
  }
  function startOfWeek(day, startOfWeek) {
    while (isoWeekday(day) % 7 !== startOfWeek) {
      day = add(day, -1, "day");
    }
    return day;
  }
  function endOfWeek(day, startOfWeek) {
    var eow = (startOfWeek + 6) % 7;
    while (isoWeekday(day) % 7 !== eow) {
      day = add(day, 1, "day");
    }
    return day;
  }
});