define("ember-power-calendar/components/power-calendar-multiple", ["exports", "ember-power-calendar/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var _default = _exports.default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-multiple/days',
    _calendarType: 'multiple',
    // CPs
    selected: Ember.computed({
      get: function get() {
        return undefined;
      },
      set: function set(_, v) {
        return Ember.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }
    }),
    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');
      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Actions
    actions: {
      select: function select(dayOrDays, calendar, e) {
        (false && !(Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date) && Ember.assert("The select action expects an array of date objects, or a date object. ".concat(_typeof(dayOrDays), " was recieved instead."), Ember.isArray(dayOrDays) || dayOrDays instanceof Object && dayOrDays.date instanceof Date));
        var action = this.get("onSelect");
        var days;
        if (Ember.isArray(dayOrDays)) {
          days = dayOrDays;
        } else if (dayOrDays instanceof Object && dayOrDays.date instanceof Date) {
          days = [dayOrDays];
        }
        if (action) {
          action(this._buildCollection(days), calendar, e);
        }
      }
    },
    // Methods
    _buildCollection: function _buildCollection(days) {
      var selected = this.get("publicAPI.selected") || [];
      var _iterator = _createForOfIteratorHelper(days),
        _step;
      try {
        var _loop = function _loop() {
          var day = _step.value;
          var index = selected.findIndex(function (selectedDate) {
            return (0, _emberPowerCalendarUtils.isSame)(day.date, selectedDate, "day");
          });
          if (index === -1) {
            selected = [].concat(_toConsumableArray(selected), [day.date]);
          } else {
            selected = selected.slice(0, index).concat(selected.slice(index + 1));
          }
        };
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          _loop();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: selected
      });
    }
  });
});